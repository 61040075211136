import logo from './logo.svg';
import './App.css';
import LinkedinTool from './src/page/mainPage';
import SearchTool from "./src/page/searchPage"
function App() {
  return (
    <div className="App">
      {window.location.href.indexOf('searches') !== -1 && <>
        <SearchTool />
      </>}
      {window.location.href.indexOf('searches') === -1 && <>
        <LinkedinTool />
      </>}
     
    </div>
  );
}

export default App;
