import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Paper, Select, MenuItem, FormControl, InputLabel, Box, Container, Typography, TextField, Button, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faDatabase, faBuilding, faComments, faSpider } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';


const CardComponent = ({ name, createdAt, newUpdates, status, data }) => {
    let results = data[0]?.data?.filter(dt => dt.savedSearch === name)

    if (results && results.length > 0) {
        status = "started"
    }
    return (
        <Card sx={{ maxWidth: 345, margin: 2 }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    {name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Created At: {new Date(createdAt).toLocaleDateString()}
                </Typography>
                <Typography variant="body2">
                    Status:<b>{status}  </b>
                </Typography>
                <Typography variant="body2">
                    New Updates: {newUpdates}
                </Typography>
                {results && <Typography variant="body2">
                    Already collected: {results && results.length}
                </Typography>}
            </CardContent>
        </Card>
    );
};

const LinkedinTool = () => {
    // State hooks for each input field
    const [sessionCookie, setSessionCookie] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [savedSearch, setSavedSearch] = useState('');
    const [saved, setSaved] = useState(false);
    const [savedYourSearch, setSavedYourSearch] = useState(false);
    const [selectedSearch, setSelectedSearch] = useState(null);
    const [onlyUpdatedRows, setOnlyUpdatedRows] = useState(false);
    const [showDataGrid, setShowDatagrid] = useState(false);
    const [receivedData, setReceivedData] = useState(false);
    const [data, setData] = useState([])
    const [allSearches, setAllSearches] = useState([]);
    const [allData, setAllData] = useState(null);
    const [searchText, setSearchText] = useState(''); // New state for search text


    const handleCheckboxChange = (event) => {
        setOnlyUpdatedRows(event.target.checked);
    };
    async function fetchSearches(data, session) {
        setAllSearches(await getSavedSearch(data, session))
    }
    useEffect(() => {
        const data = localStorage.getItem('sessionCookie')
        if (data) {
            let session = localStorage.getItem("sessionId")
            setSaved(true)
            fetchSearches(data, session.split('"').join(''))


        }
        const yourSearch = localStorage.getItem('savedSearch')

        if (yourSearch) {
            setSavedYourSearch(true)
        }
    }, [])
    // Handler for updating sessionCookie state
    const handleSessionCookieChange = (event) => {
        setSessionCookie(event.target.value);
    };

    const handleSavedSearchChange = (event) => {
        setSavedSearch(event);
    }
    // Handler for updating sessionId state
    const handleSessionIdChange = (event) => {
        setSessionId(event.target.value);
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const filteredRows = data?.filter(row =>
        Object.values(row).some(value =>
            String(value).toLowerCase().includes(searchText.toLowerCase())
        )
    );


    const handleSavedSearch = () => {
        localStorage.setItem('savedSearch', savedSearch);
        toast.success('Saved Search inserted successfully!');
        setSaved(true)
        setSavedYourSearch(true)
    };
    // Function to save data to localStorage and show toast
    const handleSave = () => {
        localStorage.setItem('sessionCookie', sessionCookie);
        localStorage.setItem('sessionId', sessionId);
        toast.success('Information saved successfully!');
        setSaved(true)
    };

    const getSavedSearch = async (cookies, session_token) => {
        try {
            const response = await axios.get('https://linkedin.bessemer.io/savedSearch', {
                headers: {
                    'accept': '*/*',
                    'accept-language': 'en-US,en;q=0.9',
                    'next-cookie': cookies,
                    'csrf-token': session_token
                }
            });
            return response.data;

        } catch (error) {
            console.error(error);
            return null;
        }
    };
    async function getAllSavedSearch(cookies, sessionCookies) {
        if (!selectedSearch) {
            let savedSearch = await localStorage.getItem('savedSearch')
            let results = await getSavedSearch(cookies, sessionCookies)
            if (savedSearch) {
                results?.elements?.map(element => {
                    if (element.name.toLowerCase() == savedSearch.toLowerCase()) {
                        setSelectedSearch(element)
                    }
                })
            }

        }

    }
    useEffect(() => {
        const sessionID = localStorage.getItem('sessionId')?.split('"').join('')
        const cookies = localStorage.getItem('sessionCookie')
        getAllSavedSearch(cookies, sessionID)
    }, [savedYourSearch])


    useEffect(() => {
        console.log(allSearches)
    }, [allSearches])
    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'location', headerName: 'Location', width: 150 },
        { field: 'fullname', headerName: 'Full Name', width: 150 },
        { field: 'summary', headerName: 'Summary', width: 200 },
        { field: 'companyHistory', headerName: 'Company History', width: 150 },
        { field: 'pastPosition', headerName: 'Past Position', width: 150 },
        { field: 'currentCompany', headerName: 'Current Company', width: 100 },
        { field: 'userID', headerName: 'User ID', width: 100 },
        { field: 'companyID', headerName: 'Company ID', width: 100 },
        { field: 'companyDescription', headerName: 'Company Description', width: 100 },
        { field: 'companyName', headerName: 'Company Name', width: 100 },
        { field: 'twoYearlygrowthHeadcount', headerName: '2-Year Growth Headcount', width: 50 },
        { field: 'yearlygrowthHeadcount', headerName: 'Yearly Growth Headcount', width: 50 },
        { field: 'sixgrowthHeadcount', headerName: '6-Month Growth Headcount', width: 50 },
        { field: 'companyHeadquarter', headerName: 'Company Headquarters', width: 50 },
        { field: 'companyWebsite', headerName: 'Company Website', width: 50 },
        { field: 'companyRevenue', headerName: 'Company Revenue', width: 50 },
        { field: 'companyIndustry', headerName: 'Company Industry', width: 50 },
        { field: 'companyLocation', headerName: 'Company Location', width: 50 },
        { field: 'companyLinkedInWebsite', headerName: 'Company LinkedIn', width: 50 },
        { field: 'userId', headerName: 'User LinkedIn URL', width: 250 },
        { field: 'contactInfo', headerName: 'Contact Info', width: 200 },
        { field: 'LastEducation', headerName: 'Last Education', width: 300 },
        { field: 'PastEducation', headerName: 'Past Education', width: 200 },
        { field: 'yearFounded', headerName: 'Year Founded', width: 200 },
        { field: 'last_activity', headerName: 'Last Activity', width: 200 },
        { field: 'last_email_date', headerName: 'Last Email Date', width: 200 },
        { field: 'ownerName', headerName: 'Owner Name', width: 200 },
        { field: 'salesforce_url', headerName: 'Salesforce URL', width: 250 },
        { field: 'Name', headerName: 'Name', width: 200 },
        { field: 'Website', headerName: 'Website', width: 200 },
        { field: 'Industry', headerName: 'Industry', width: 200 },
        { field: 'Organization_Id', headerName: 'Organization ID', width: 200 },
        { field: 'Last_Email', headerName: 'Last Email', width: 200 },
        { field: 'Source_of_Introduction__Full_Name_', headerName: 'Source of Introduction Full Name', width: 250 },
        { field: 'Source_of_Introduction__Email_', headerName: 'Source of Introduction Email', width: 250 },
        { field: 'LinkedIn_URL', headerName: 'LinkedIn URL', width: 250 },
        { field: 'Number_of_Employees', headerName: 'Number of Employees', width: 200 },
        { field: 'Last_Funding_Amount__USD_', headerName: 'Last Funding Amount (USD)', width: 250 },
        { field: 'Total_Funding_Amount__USD_', headerName: 'Total Funding Amount (USD)', width: 250 },
        { field: 'Last_Funding_Date', headerName: 'Last Funding Date', width: 200 },
        { field: 'Employees__12_Months_Ago', headerName: 'Employees 12 Months Ago', width: 250 },
        { field: 'Employees__Growth_YoY____', headerName: 'Employees Growth YoY', width: 200 },
        { field: '_version_', headerName: 'Version', width: 200 },
    ]
    const getData = async () => {
        const sessionID = localStorage.getItem('sessionId')?.split('"').join('')

        try {
            const url = `https://automata.bessemer.io/api/get-data/${sessionID}`;
            const response = await axios.get(url);
            const datalog = response.data
            setAllData(datalog)
        } catch (err) {
            console.error(err.response ? err.response.data : err.message);
        }
    };
    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Container maxWidth="md">
                <Box sx={{ display: 'flex', alignItems: 'center', py: 3 }}>
                    {!showDataGrid && <Box sx={{ display: 'flex', flexDirection: 'column', width: '15%' }}>
                        <IconButton onClick={() => { window.location.href = "/" }}>
                            <FontAwesomeIcon icon={faSearch} />
                        </IconButton>
                        <Typography onClick={() => { window.location.href = "/" }} variant="caption">Search</Typography>

                        <IconButton onClick={() => { window.location.href = "/searches" }}>
                            <FontAwesomeIcon icon={faDatabase} />
                        </IconButton>
                        <Typography onClick={() => { window.location.href = "/searches" }} variant="caption">Collection</Typography>


                    </Box>}

                    <Box sx={{ flexGrow: 1, pl: 3 }}>
                        <Typography variant="h5" gutterBottom>
                            All your saved searches
                        </Typography>
                        {!saved && <Box sx={{ backgroundColor: '#F1F3F4', p: 3, borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Connect to LinkedIn
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                To automate actions on your behalf, Bessemer Ghost Killer connects to your Sales Navigator account using your session cookie.
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                Every time you log into Sales Navigator on your browser, a new cookie is created for that "session". If you log out or are disconnected, the cookie expires.
                            </Typography>

                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Your Sales Navigator session cookie"
                                placeholder="Paste your cookies here"
                                value={sessionCookie}
                                onChange={handleSessionCookieChange}
                                sx={{ my: 2 }}
                            />

                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Your sales navigator session ID"
                                placeholder="Paste your session ID here"
                                value={sessionId}
                                onChange={handleSessionIdChange}
                                sx={{ my: 2 }}
                            />

                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="contained" color="primary">
                                    Connect to LinkedIn
                                </Button>
                                <Button disabled={sessionCookie.length === 0 || sessionId.length === 0} variant="outlined" onClick={handleSave}>
                                    Save
                                </Button>
                            </Box>
                        </Box>}
                        {saved && <Box sx={{ backgroundColor: '#F1F3F4', p: 3, borderRadius: 2 }}>
                            {allSearches && allSearches.elements && <>
                                <Grid container spacing={2}>
                                    {allSearches.elements.map((item, index) => {
                                        return <Grid item xs={12} sm={6} md={4} key={index}>

                                            <CardComponent
                                                name={item.name}
                                                createdAt={item.createdAt}
                                                newUpdates={item.newHitsCount}
                                                status={"not running"}
                                                data={allData}
                                            />
                                        </Grid>
                                    }

                                    )}
                                </Grid>
                            </>}

                        </Box>}


                    </Box>

                </Box>

            </Container>
            {saved && savedYourSearch && showDataGrid && <Box
                sx={{
                    width: "100vw",
                    height: "calc(100vh - 40px)", // Adjust height to fill almost the whole screen
                    padding: "20px",
                    boxSizing: 'border-box' // Ensures padding is included in the height
                }}
            >
           
                <div style={{ height: '100%', width: "80%", marginLeft: "10%" }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Search"
                    placeholder="Type to search..."
                    value={searchText}
                    onChange={handleSearchTextChange}
                    sx={{ my: 2 }}
                />
                    <Typography style={{ marginBottom: "12px" }} variant='body2'>You currently have scraped around {data && data.length} profiles.</Typography>
                    <Button variant='outlined' style={{ marginBottom: "12px" }} onClick={() => { setShowDatagrid(false) }}>Go back</Button>
                    <Button variant='outlined' style={{ marginBottom: "12px", marginLeft: "6px" }} onClick={() => { setShowDatagrid(false) }}>Export CSV</Button>

                    {data && <DataGrid
                        rows={filteredRows && filteredRows.map((dt, idx) => ({ ...dt, id: idx }))}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        disableSelectionOnClick
                        autoHeight // Adjust DataGrid height to fit its container
                    />}
                </div>
            </Box>}
            {/* ToastContainer to display toasts */}
            <ToastContainer />
        </>
    );
}

export default LinkedinTool;
